<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-text-field
              label="Email"
              autocomplete="off"
              type="text"
              color="secondary"
              v-model="email"
              hide-details
              value=""
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            class="text-right pt-0"
        >
          <v-btn
              color="secondary"
              @click="update"
              depressed
          >
            Update Email
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'EmailSettings',
  props: {
    emailPreset: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    email: '',
  }),
  methods: {
    ...mapActions(['setAlerts', 'updateUserSettings']),
    async update () {
      this.setAlerts(null)

      if (this.email) {
        if (this.email.length > 0) {
          try {
            const authToken = await this.$auth.getTokenSilently();

            await this.updateUserSettings({
              email: this.email,
              authToken: authToken
            })

            this.setAlerts({
              active: true,
              color: 'secondary',
              message: 'Your email was updated. From now on, you will have to log in with your new email.'
            })
          } catch (error) {
            console.log(error)
            this.setAlerts({
              active: true,
              color: 'error',
              message: 'We encountered an error while updating your email. Your email was not updated.'
            })
          }
        } else {
          this.setAlerts({
            active: true,
            color: 'error',
            message: 'Please make sure you submitted a valid email address. Your email was not updated.'
          })
        }
      } else {
        this.setAlerts({
          active: true,
          color: 'error',
          message: 'Please make sure you submitted a valid email address. Your email was not updated.'
        })
      }
    }
  },
  watch: {
    emailPreset (value) {
      if (value) {
        if (value.length > 0) {
          this.email = value
        }
      }
    }
  }
}
</script>

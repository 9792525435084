<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-text-field
              label="New Password"
              type="password"
              color="secondary"
              v-model="password"
              autocomplete="off"
              hide-details
              value=""
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-text-field
              label="Confirm New Password"
              type="password"
              color="secondary"
              v-model="passwordConfirmation"
              autocomplete="off"
              hide-details
              value=""
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            class="text-right pt-0"
        >
          <v-btn
              color="secondary"
              @click="update"
              depressed
          >
            Update Password
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'PasswordSettings',
  data: () => ({
    password: '',
    passwordConfirmation: '',
  }),
  methods: {
    ...mapActions(['setAlerts', 'updateUserSettings']),
    async update () {
      this.setAlerts(null)

      if (this.password && this.passwordConfirmation) {
        if ((this.password.length > 7 && this.passwordConfirmation.length > 7) && (this.password === this.passwordConfirmation)) {
          try {
            const authToken = await this.$auth.getTokenSilently();

            await this.updateUserSettings({
              password: this.password,
              authToken: authToken
            })

            this.setAlerts({
              active: true,
              color: 'secondary',
              message: 'Your password was updated. From now on, you will have to log in with your new password.'
            })

            this.password = '';
            this.passwordConfirmation = '';
          } catch (error) {
            console.log(error)
            this.setAlerts({
              active: true,
              color: 'error',
              message: 'We encountered an error while updating your password. Your password was not updated.'
            })
          }
        } else {
          this.setAlerts({
            active: true,
            color: 'error',
            message: 'Please make sure you submitted a valid password that is at least 8 characters long and includes special characters. Your password was not updated.'
          })
        }
      } else {
        this.setAlerts({
          active: true,
          color: 'error',
          message: 'Please make sure you submitted a valid password that is at least 8 characters long and includes special characters. Your password was not updated.'
        })
      }
    }
  },
}
</script>

<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-combobox
              v-model="selectedMetrics"
              :items="availableMetrics"
              item-value="value"
              item-text="name"
              item-color="secondary"
              color="secondary"
              label="Patient Metrics"
              hide-details
              small-chips
              multiple
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            class="text-right pt-0"
        >
          <v-btn
              color="secondary"
              @click="update"
              depressed
          >
            Update Metrics
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'MetricsSettings',
  props: {
    metricsPreset: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    metrics: [],
    selectedMetrics: [],
    availableMetrics: []
  }),
  async created () {
    try {
      const authToken = await this.$auth.getTokenSilently();

      this.availableMetrics = await this.fetchMetrics({
        authToken: authToken
      })
    } catch (error) {
      console.log(error)

      this.setAlerts({
        active: true,
        color: 'error',
        message: 'We encountered an error while fetching your metrics.'
      })
    }
  },
  methods: {
    ...mapActions(['setAlerts', 'fetchMetrics', 'updateMetrics']),
    async update () {
      this.setAlerts(null)

      if (this.selectedMetrics) {
        if (this.selectedMetrics.length > 0) {
          try {
            const authToken = await this.$auth.getTokenSilently();

            await this.updateMetrics({
              metrics: this.selectedMetrics,
              authToken: authToken
            })

            this.setAlerts({
              active: true,
              color: 'secondary',
              message: 'Your preset metrics were updated.'
            })
          } catch (error) {
            console.log(error)
            this.setAlerts({
              active: true,
              color: 'error',
              message: 'We encountered an error while updating your metrics. Your metrics were not updated.'
            })
          }
        } else {
          this.setAlerts({
            active: true,
            color: 'error',
            message: 'Please make sure you submitted a valid metrics. Your metrics were not updated.'
          })
        }
      } else {
        this.setAlerts({
          active: true,
          color: 'error',
          message: 'Please make sure you submitted valid metrics. Your metrics were not updated.'
        })
      }
    }
  },
  watch: {
    metricsPreset (value) {
      console.log('value: ', value)
      if (value) {
        if (value.length > 0) {
          this.selectedMetrics = value
        }
      }
    }
  }
}
</script>

<template>
  <v-main class="pt-0 fill-height">
    <v-container class="pa-0" fluid fill-height>
      <v-layout child-flex>
        <v-row>
          <v-col
              cols="12"
              md="6"
              lg="4"
          >
            <v-row>
              <v-col>
                <email-settings
                    :emailPreset="email"
                ></email-settings>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <password-settings></password-settings>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="12"
              md="6"
              lg="4"
          >
            <metrics-settings
                :metricsPreset="metrics"
            ></metrics-settings>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import EmailSettings from "../components/settings/Email";
import PasswordSettings from "../components/settings/Password";
import { mapActions } from "vuex";
import MetricsSettings from "../components/settings/PresetMetrics";

export default {
  name: 'Settings',
  components: {MetricsSettings, PasswordSettings, EmailSettings },
  data: () => ({
    email: '',
    metrics: []
  }),
  async created () {
    let settings = {}

    try {
      const authToken = await this.$auth.getTokenSilently();

      settings = await this.fetchUserSettings({
        authToken: authToken
      })

      this.email = settings.email

      if (settings.user_metadata) {
        if (settings.user_metadata.metrics) {
          this.metrics = settings.user_metadata.metrics
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setOverlay(false)
      this.setLoader(false)
    })
  },
  methods: {
    ...mapActions(['setLoader', 'setOverlay', 'fetchUserSettings']),
  }
};
</script>
